<template>
  <div class="padding protocol">
    <p
      v-for="(item, index) in datas"
      :key="index"
      :class="
        item.id === 2153 ||
        item.id === 2154 ||
        item.id === 2471 ||
        item.id === 2472
          ? 'weight'
          : ''
      "
    >
      {{ item.content }}
    </p>
  </div>
</template>

<script>
import { getPrivacyPolicy } from "@/api/agreement/privacy";
export default {
  name: "Privacy",
  data() {
    return {
      datas: null,
      params: {
        // 3:隐私协议， 4:服务条款
        type: this.$route.query.type,
      },
    };
  },
  beforeCreate() {
    const { type } = this.$route.query;
    switch (type) {
      case "3":
        document.title = "隱私協議";
        break;
      case "4":
        document.title = "服務條款";
        break;
    }
  },
  mounted() {
    this.getPrivacyPolicy();
  },
  methods: {
    getPrivacyPolicy() {
      getPrivacyPolicy(this.params).then((res) => {
        this.datas = res.data;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/agreement.scss";
</style>
