import request, { baseUrl } from "@/utils/request";

/**
 * 获取隐私协议内容
 * @param {*} params
 * @returns
 */
export function getPrivacyPolicy(params) {
  return request({
    url: `${baseUrl.api}/article/getPrivacyPolicy`,
    method: "GET",
    headers: {
      loading: false,
    },
    params,
  });
}
